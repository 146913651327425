import React from 'react'
import { InlineGroup, InlineTextarea } from 'react-tinacms-inline'
import { useAreaContext } from '../../common/contexts/areaContext'
import { Button } from '../../common/button'

export function StationContactButtons({ data }) {
  const basePath = useAreaContext()
  return (
    <InlineGroup
      focusRing={{ offset: { x: -32, y: 0 }, borderRadius: 0 }}
      insetControls
      name="contact"
      fields={[
        {
          name: 'jobMail',
          label: 'Email-Adresse für Bewerbungen',
          component: 'text',
        },
        {
          name: 'jobMailsCc',
          label: 'Cc-Adressen für Bewerbungen',
          component: 'list',
          defaultItem: 'bewerbung-jukebox@uke.de',
          field: {
            component: 'text',
          },
        },
      ]}
    >
      <section id="contact" className="pb-0">
        <div className="row align-items-center py-0">
          <div className="col-md-10 offset-md-1 col-12">
            <h2>
              <InlineTextarea
                name="headline"
                focusRing={{ offset: 0 }}
                placeholder="Headline Kontakt"
              />
            </h2>
          </div>

          <div className="col-md-10 offset-md-1 col-12 mt-5 text-center">
            <Button
              className="px-5"
              color="blue"
              href={getMailToString(data, basePath)}
            >
              Jetzt bewerben
            </Button>
            <p className="mt-2">
              <small>bewerbung-jukebox@uke.de</small>
            </p>
          </div>

          <div className="col-md-10 offset-md-1 col-12 mt-5">
            <h2>
              <InlineTextarea
                name="headlineContactPerson"
                focusRing={{ offset: 0 }}
                placeholder="Headline Ansprechpartner"
              />
            </h2>
          </div>
        </div>
      </section>
    </InlineGroup>
  )
}

const getMailToString = (data) => {
  const subject =
    'Meine Bewerbung für: ' +
    (data.header && data.header.title ? data.header.title : '')
  const mailRecipient =
    data.contact && data.contact.jobMail ? data.contact.jobMail : 'uke@uke.de'

  const ccRecipients =
    data.contact && data.contact.jobMailsCc
      ? data.contact.jobMailsCc.join(', ')
      : ''

  return (
    'mailto:' +
    mailRecipient +
    '?subject=' +
    encodeURIComponent(subject) +
    '&cc=' +
    ccRecipients
  )
}

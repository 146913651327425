import React from 'react'
import { Link } from 'gatsby'
import { Icon } from '../../common/icon'
import { InlineGroup, InlineTextarea } from 'react-tinacms-inline'
import { CustomImageGroup } from '../image'
import { useFavoritesContext } from '../../common/contexts/favoritesContext'
import { useCMS } from 'tinacms'
import { Button } from '../../common/button'

export function StationHeader({ data, entityForm, locationState, stationId }) {
  const { favorites, toggleFavorite } = useFavoritesContext()
  const cms = useCMS()

  const slug = data.slug
  const handleFavClick = (event) => {
    event.preventDefault()
    stationId && toggleFavorite(stationId)
  }

  const carouselTitle =
    locationState && locationState.carouselTitle
      ? locationState.carouselTitle
      : undefined
  const carouselSlugs =
    locationState && locationState.carouselSlugs
      ? locationState.carouselSlugs
      : []

  return (
    <InlineGroup
      name="header"
      focusRing={{ offset: { x: -32, y: 0 }, borderRadius: 0 }}
    >
      <header
        className={`bg-img-yellow divider-left ${
          cms.enabled ? '' : 'header--static'
        }`}
      >
        <div className="row align-items-center">
          <div className="col-md-5 offset-md-1 col-10 offset-1 text-center">
            <CustomImageGroup
              name="handlettering"
              data={data.header ? data.header.handlettering : {}}
              frameEnabledByDefault={false}
              uploadDir={'/stations/handletterings/'}
            />
          </div>

          <div className="col-md-5 offset-md-0 col-10 offset-1 text-center mt-4 mt-lg-0">
            <CustomImageGroup
              name="image"
              data={data.header ? data.header.image : {}}
              entityForm={entityForm}
              frameEnabledByDefault={true}
              uploadDir={data.mediaDir}
            />
          </div>
          <div className="col-md-10 col-10 offset-1 text-center mt-4">
            <h1>
              <InlineTextarea
                name="title"
                focusRing={{ offset: 4 }}
                placeholder="Seitentitel"
              >
                {data.header ? data.header.title : ''}
              </InlineTextarea>
            </h1>
            <h3>
              <InlineTextarea
                name="introText"
                focusRing={{ offset: 4 }}
                placeholder="Einleitungstext"
              >
                {data.header ? data.header.introText : ''}
              </InlineTextarea>
            </h3>
            <div className="my-4">
              <Button color="blue" href="#contact" className="m-2">
                Kontakt
              </Button>
              {stationId ? (
                <Button
                  color={
                    favorites && favorites.includes(stationId)
                      ? 'darker-blue'
                      : 'blue'
                  }
                  className="m-2"
                  onClick={handleFavClick}
                >
                  Merken
                </Button>
              ) : null}
            </div>
          </div>

          <ul
            id="job-indicator"
            className="col-md-10 col-10 offset-1 text-center mb-2"
          >
            <InlineGroup
              name="hasVacancies"
              insetControls
              fields={[
                {
                  name: '',
                  label: 'Freie Stelle vorhanden?',
                  component: 'toggle',
                },
              ]}
            >
              {data.header && data.header.hasVacancies === true ? (
                <li className="job-green d-inline-block">
                  Wir haben eine freie Stelle
                </li>
              ) : (
                <li className="job-red d-inline-block">
                  Wir haben zur Zeit leider keine freie Stelle
                </li>
              )}
            </InlineGroup>
          </ul>
          {carouselTitle &&
            carouselSlugs &&
            carouselSlugs.length > 1 && ( // TODO: CLEANUP!
              <div id="match" className="col-md-10 offset-md-1 col-12">
                <div className="match_nav">
                  <Link
                    to={carouselSlugs[0]}
                    state={locationState}
                    className="mx-1"
                    style={{
                      opacity:
                        carouselSlugs.indexOf(slug) === 0 ? 0.3 : undefined,
                    }}
                  >
                    <Icon name="first" height="40" width="40" />
                  </Link>
                  <Link
                    to={carouselSlugs[carouselSlugs.indexOf(slug) - 1]}
                    state={locationState}
                    className="mx-1"
                    style={{
                      opacity:
                        carouselSlugs.indexOf(slug) === 0 ? 0.3 : undefined,
                    }}
                  >
                    <Icon name="prev" height="40" width="40" />
                  </Link>
                </div>

                <h2 id="carousel-title" className="m-0 text-center">
                  {carouselTitle} (
                  {`${carouselSlugs.indexOf(slug) + 1}/${carouselSlugs.length}`}
                  )
                </h2>

                <div className="match_nav">
                  <Link
                    to={carouselSlugs[carouselSlugs.indexOf(slug) + 1]}
                    state={locationState}
                    className="mx-1"
                    style={{
                      opacity:
                        carouselSlugs.indexOf(
                          carouselSlugs[carouselSlugs.indexOf(slug) + 1]
                        ) === -1
                          ? 0.3
                          : undefined,
                    }}
                  >
                    <Icon name="next" height="40" width="40" />
                  </Link>
                  <Link
                    to={carouselSlugs[carouselSlugs.length - 1]}
                    state={locationState}
                    className="mx-1"
                    style={{
                      opacity:
                        carouselSlugs.indexOf(
                          carouselSlugs[carouselSlugs.indexOf(slug) + 1]
                        ) === -1
                          ? 0.3
                          : undefined,
                    }}
                  >
                    <Icon name="last" height="40" width="40" />
                  </Link>
                </div>
              </div>
            )}
        </div>
      </header>
    </InlineGroup>
  )
}

import React from 'react'
import { BlocksControls, InlineBlocks } from 'react-tinacms-inline'
import { Image, ImageBlock } from '../image'
import {
  ContactChannelPhone,
  ContactChannelMobile,
  ContactChannelMail,
  ContactChannelPhoneBlock,
  ContactChannelMobileBlock,
  ContactChannelMailBlock,
} from '../contactChannel'
import { useCMS } from 'tinacms'
import ReactMarkdown from 'react-markdown'
import { InlineWysiwyg } from 'react-tinacms-editor'

export function StationContact({ data, entityContext, index }) {
  const cms = useCMS()

  return (
    <BlocksControls index={index} focusRing={{ offset: { x: -32, y: 0 } }}>
      <section className={`mt-0 ${cms.enabled ? '' : 'contact--static'}`}>
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="row align-items-center">
              <div className="col-md-6 col-12 order-1 order-lg-1">
                <InlineBlocks
                  name="image"
                  direction="vertical"
                  className="contact-image"
                  min={0}
                  max={1}
                  blocks={getStationContactImageBlocks(entityContext)}
                  itemProps={{
                    entityContext: entityContext,
                    frameEnabledByDefault: false,
                  }}
                />
              </div>

              <div className="col-md-6 order-md-2 col-12 order-3 order-lg-2">
                <ul className="contact-channels">
                  <InlineBlocks
                    name="contactChannels"
                    direction="vertical"
                    className=""
                    min={1}
                    max={10}
                    blocks={STATION_CONTACT_CHANNEL_BLOCKS}
                  />
                </ul>
              </div>

              <div className="col-md-6 col-12 order-2 order-lg-3">
                <div className="mt-3">
                  <InlineWysiwyg
                    name="contactNames"
                    format="html"
                    focusRing={{ offset: 0 }}
                  >
                    <div
                      className={`text-container ${
                        cms.enabled ? '' : 'text-container--static'
                      }`}
                    >
                      <ReactMarkdown
                        source={data.contactNames}
                        allowDangerousHtml={true}
                      />
                    </div>
                  </InlineWysiwyg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </BlocksControls>
  )
}

const getStationContactImageBlocks = (entityContext) => {
  return {
    Image: {
      Component: Image,
      template: ImageBlock(entityContext, false),
    },
  }
}

const STATION_CONTACT_CHANNEL_BLOCKS = {
  Phone: {
    Component: ContactChannelPhone,
    template: ContactChannelPhoneBlock,
  },
  Mobile: {
    Component: ContactChannelMobile,
    template: ContactChannelMobileBlock,
  },
  Mail: {
    Component: ContactChannelMail,
    template: ContactChannelMailBlock,
  },
}

import React from 'react'
import { InlineBlocks, InlineGroup } from 'react-tinacms-inline'
import { Stat, StatBlock } from '../stat'

export function StationStats() {
  return (
    <InlineGroup
      name="statistic"
      focusRing={{ offset: { x: -32, y: 0 }, borderRadius: 0 }}
    >
      <section id="matching-stats">
        <div id="stats" className="text-center">
          <div className="row align-items-center">
            <div className="col-md-3 col-12">
              <h3>Unser Bereich in Zahlen:</h3>
            </div>
            <div className="col-md-9 col-12">
              <InlineBlocks
                name="icons"
                direction="horizontal"
                className="grid-container"
                min={1}
                max={3}
                blocks={STATION_STAT_BLOCKS}
              />
            </div>
          </div>
        </div>
      </section>
    </InlineGroup>
  )
}

const STATION_STAT_BLOCKS = {
  Stat: {
    Component: Stat,
    template: StatBlock,
  },
}

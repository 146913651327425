import React from 'react'
import { BlocksControls, InlineText } from 'react-tinacms-inline'
import parsePhoneNumber from 'libphonenumber-js'

const phoneLink = (data) => {
  const phoneNumber = parsePhoneNumber(data.text, 'DE')
  let tel = '#'
  if (phoneNumber && phoneNumber.isValid() === true) {
    tel = 'tel:' + phoneNumber.number
  }
  return tel
}

const mailLink = (data) => {
  return data.text ? 'mailto:' + data.text : '#'
}

export function ContactChannelPhone({ data, index }) {
  return ContactChannel(data, index, 'phone', phoneLink(data))
}

export function ContactChannelMobile({ data, index }) {
  return ContactChannel(data, index, 'mobile', phoneLink(data))
}

export function ContactChannelMail({ data, index }) {
  return ContactChannel(data, index, 'email', mailLink(data))
}

export function ContactChannel(data, index, listClassName, link) {
  return (
    <BlocksControls index={index} focusRing={{ offset: { x: 0, y: 4 } }}>
      <li className={listClassName}>
        <a href={link}>
          <InlineText name="text" focusRing={{ offset: { x: 0, y: 0 } }} />
        </a>
      </li>
    </BlocksControls>
  )
}

export const ContactChannelPhoneBlock = {
  label: 'Festnetz',
  name: 'contactChannelPhone',
  defaultItem: {
    text: '+49 (0) 40 7410 -',
  },
  fields: [{ name: 'text', label: 'Text', component: 'text' }],
}

export const ContactChannelMobileBlock = {
  label: 'Mobil',
  name: 'contactChannelMobile',
  defaultItem: {
    text: '+49 (0) 1',
  },
  fields: [{ name: 'text', label: 'Text', component: 'text' }],
}

export const ContactChannelMailBlock = {
  label: 'E-Mail',
  name: 'contactChannelMail',
  defaultItem: {
    text: 'jukebox@uke.de',
  },
  fields: [{ name: 'text', label: 'Text', component: 'text' }],
}

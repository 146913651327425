import React from 'react'
import { useCMS } from 'tinacms'
import {
  BlocksControls,
  InlineImage,
  InlineTextarea,
} from 'react-tinacms-inline'
import { MediaSettings as MediaSetings } from '../cms/settings'

export function Stat({ data, index }) {
  const cms = useCMS()
  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }}>
      <figure>
        <InlineImage
          name="filename"
          previewSrc={() => cms.media.previewSrc(data.filename)}
          parse={(media) => MediaSetings.reposPath + media.filename}
          uploadDir={() => '/stations/icons/'}
          className="stat-icon"
          alt={data.iconName}
          title={data.iconName}
          focusRing={{ offset: 0 }}
        />
        <figcaption>
          <h4>
            <InlineTextarea name="text" focusRing={{ offset: 0 }} />
          </h4>
          <h4>
            <span>
              <InlineTextarea name="number" focusRing={{ offset: 0 }} />
            </span>
          </h4>
        </figcaption>
      </figure>
    </BlocksControls>
  )
}

export const StatBlock = {
  label: 'Info',
  name: 'icon',
  defaultItem: {
    iconName: 'Icon',
    text: 'Titel',
    number: '99',
  },
  fields: [
    { name: 'iconName', label: 'Icon-Name', component: 'text' },
    { name: 'text', label: 'Titel', component: 'text' },
    { name: 'number', label: 'Zahl', component: 'text' },
  ],
}

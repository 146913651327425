import * as React from 'react'
import {
  useCMS,
  useForm,
  usePlugin,
  ActionButton,
  Modal,
  PopupModal,
  ModalHeader,
  ModalBody,
  ModalActions,
} from 'tinacms'
import { Button } from '@tinacms/styles'
import { Editor } from '../components/editor'
import { InlineForm, InlineBlocks, InlineGroup } from 'react-tinacms-inline'
import { getSectionBlocks } from '../components/section'
import { CMSWrapper } from '../components/cmsWrapper'
import { StationHeader } from '../components/stations/stationHeader'
import { StationStats } from '../components/stations/stationStats'
import { StationContactButtons } from '../components/stations/stationContactButtons'
import { StationContact } from '../components/stations/stationContact'
import { GlobalHelmet } from '../common/seo/globalHelmet'
import { Navigation } from '../common/navigation'
import { Footer } from '../common/footer'
import { StaticReferenceModules } from '../components/references/staticReferenceModules'
import { StaticBlogs } from '../components/blogs/staticBlogs'
import {
  ImageFields,
  SidebarImageBlock,
  SidebarSectionBlock,
} from '../components/sidebarBlocks'
import {
  ContactChannelMailBlock,
  ContactChannelMobileBlock,
  ContactChannelPhoneBlock,
} from '../components/contactChannel'
import { MediaSettings } from '../cms/settings'
import { RoleCheck } from '../components/rolecheck'
import { ROLE_ADMIN } from '../cms/security'
import { alertError, isEmptyString, isValidEmail } from '../cms/validation'
import {
  getDeleteRefreshTimeout,
  refreshSourcedContent,
  refreshAndRedirectHome,
  refreshAndRedirect,
} from '../cms/refresh'
import { graphql } from 'gatsby'
import fetch from 'node-fetch'
import slugify from 'slugify'

const DeleteAction = ({ form }) => {
  const cms = useCMS()
  const [showPopup, setShowPopup] = React.useState(false)
  return (
    <>
      {showPopup ? (
        <Modal>
          <PopupModal>
            <ModalHeader> Stationsseite löschen </ModalHeader>
            <ModalBody padded={true}>
              Möchten Sie die Seite wirklich löschen ?
            </ModalBody>
            <ModalActions>
              <Button onClick={() => setShowPopup(false)}> Abbrechen </Button>
              <Button
                onClick={async () => {
                  await fetch(`/_cms/stations/${form.values._id}`, {
                    method: 'DELETE',
                  })
                    .then(async () => {
                      await refreshAndRedirectHome(getDeleteRefreshTimeout())
                      cms.alerts.success('Die Seite wurde gelöscht!')
                    })
                    .catch((e) => {
                      cms.alerts.error('Fehler beim Löschen der Seite!')
                      console.log(e)
                    })
                  setShowPopup(false)
                  window.history.back()
                }}
                primary
              >
                Löschen
              </Button>
            </ModalActions>
          </PopupModal>
        </Modal>
      ) : null}
      <RoleCheck requiredRoles={[ROLE_ADMIN]}>
        <ActionButton onClick={() => setShowPopup(true)}>
          Seite löschen
        </ActionButton>
      </RoleCheck>
    </>
  )
}

export default function Station({ data, location, pageContext }) {
  let initialDataLoader

  const loadStation = async () => {
    const stationsResponse = await fetch(`/_cms/stations/${pageContext.id}`)
    return stationsResponse.json()
  }

  if (process.env.NODE_ENV === 'production') {
    initialDataLoader = {
      initialValues: data.station,
    }
  }
  if (process.env.NODE_ENV !== 'production') {
    initialDataLoader = {
      async loadInitialValues() {
        console.log('Loading cms data asynchronously')
        return loadStation().then((station) => ({
          ...station,
          metainfo: {
            ...station.metainfo,
            slug: station.slug,
          },
        }))
      },
    }
  }
  return (
    <StationTemplate
      data={data}
      location={location}
      initialDataLoader={initialDataLoader}
    />
  )
}

function StationTemplate({ data, location, initialDataLoader }) {
  const clusters = data.allCluster.nodes
  const station = data.station
  const stationBlogs = data.allBlog.nodes
  const stationReferences = data.allReference.nodes

  const cms = useCMS()

  const entityContext = {
    entityType: 'station',
    mediaDir: station.mediaDir,
  }

  const StationForm = {
    label: 'Stationsseite',
    actions: [DeleteAction],
    fields: [
      {
        label: 'Meta-Info',
        name: 'metainfo',
        component: 'group',
        fields: [
          {
            name: 'slug',
            label: 'URL-Pfad',
            component: 'text',
            description:
              'Pfad der Seite in der URL. Warnung: Änderung nur mit Vorsicht!',
            format: (value) =>
              value &&
              slugify(
                value.substring(value.lastIndexOf('/') + 1).toLowerCase(),
                { locale: 'de' }
              ),
          },
          {
            name: 'stationId',
            label: 'Stations-Kennung',
            description:
              'Interne Kennung für den Abgleich der Mittelwert-Datei',
            component: 'text',
          },
          {
            label: 'Clusters',
            name: 'clusters',
            component: 'list',
            defaultItem: clusters.length > 0 ? clusters[0].key : '', // must be set, otherwise first selection is empty!
            field: {
              component: 'select',
              options: clusters.map((cluster) => {
                const title =
                  cluster.title.length > 25
                    ? cluster.title.substring(0, 25) + '...'
                    : cluster.title
                return { value: cluster.key, label: title }
              }),
            },
          },
          {
            label: 'Tags',
            name: 'tags',
            component: 'tags',
          },
          {
            label: 'Bereiche',
            name: 'areas',
            component: 'list',
            defaultItem: 'CARE',
            field: {
              component: 'select',
              options: [
                {
                  value: 'CARE',
                  label: 'Pflege',
                },
                {
                  value: 'MFA',
                  label: 'MFA',
                },
                {
                  value: 'MTLA',
                  label: 'MTLA',
                },
                {
                  value: 'MTRA',
                  label: 'MTRA',
                },
              ],
            },
          },
          {
            name: 'suitableForGeriatricNurses',
            label: 'Für examinierte Altenpflegende geeignet',
            component: 'toggle',
          },
        ],
      },
      {
        label: 'Kopfbereich',
        name: 'header',
        component: 'group',
        fields: [
          {
            label: 'Handlettering',
            name: 'handlettering',
            component: 'group',
            defaultItem: {
              showFrame: false,
            },
            fields: ImageFields('/stations/handletterings/', false),
          },
          {
            label: 'Stations-Bild',
            name: 'image',
            component: 'group',
            defaultItem: {
              showFrame: true,
            },
            fields: ImageFields(entityContext.mediaDir, true),
          },
          {
            label: 'Seitentitel',
            name: 'title',
            component: 'textarea',
          },
          {
            name: 'introText',
            label: 'Einleitungstext',
            component: 'textarea',
          },
          {
            name: 'slogan',
            label: 'Slogan',
            component: 'textarea',
          },
          {
            name: 'hasVacancies',
            label: 'Freie Stellen',
            component: 'toggle',
          },
        ],
      },
      {
        label: 'Station in Zahlen',
        name: 'statistic',
        component: 'group',
        fields: [
          {
            label: 'Station in Zahlen',
            name: 'icons',
            component: 'blocks',
            templates: {
              Stat: SidebarStatBlock,
            },
          },
        ],
      },
      {
        label: 'Inhalt',
        name: 'content',
        component: 'group',
        fields: [
          {
            label: 'Sections',
            name: 'sections',
            component: 'blocks',
            templates: {
              Section: SidebarSectionBlock(entityContext),
            },
          },
        ],
      },
      {
        label: 'Kontakt',
        name: 'contact',
        component: 'group',
        fields: [
          {
            label: 'Überschrift Kontakt',
            name: 'headline',
            component: 'text',
          },
          {
            name: 'jobMail',
            label: 'Email-Adresse für Bewerbungen',
            component: 'text',
          },
          {
            name: 'jobMailsCc',
            label: 'Cc-Adressen für Bewerbungen',
            component: 'list',
            defaultItem: 'bewerbung-jukebox@uke.de',
            field: {
              component: 'text',
            },
          },
          {
            label: 'Überschrift Ansprechpartner',
            name: 'headlineContactPerson',
            component: 'text',
          },
          {
            label: 'Info',
            name: 'contactInfos',
            component: 'blocks',
            templates: {
              ContactInfo: SidebarContactInfoBlock(entityContext),
            },
          },
        ],
      },
      {
        name: 'isStationOfWeek',
        label: 'Station der Woche',
        description: 'Setzt die bisherige Station der Woche zurück!',
        component: 'toggle',
      },
      {
        name: 'isDraft',
        component: 'toggle',
        label: 'Entwurf',
        description: 'Als Entwurf speichern',
      },
    ],
    onSubmit(formData) {
      const oldSlug = formData.slug
      const newSlug = formData.metainfo.slug.startsWith('/')
        ? formData.metainfo.slug
        : `${formData.slug.substring(0, formData.slug.lastIndexOf('/'))}/${
            formData.metainfo.slug
          }`

      const hasSlugChanged = oldSlug !== newSlug

      // Field validation
      if (!formData.header || isEmptyString(formData.header.title)) {
        return alertError(cms, 'Der Stations-Titel darf nicht leer sein!')
      } else if (isEmptyString(formData.contact.jobMail)) {
        return alertError(
          cms,
          'Das Feld "Email-Adresse für Bewerbungen" darf nicht leer sein!'
        )
      } else if (!isValidEmail(formData.contact.jobMail)) {
        return alertError(
          cms,
          'Das Feld "Email-Adresse für Bewerbungen" enthält keine gültige Email-Adresse!'
        )
      } else if (
        formData.metainfo.clusters &&
        checkIfDuplicateExists(formData.metainfo.clusters)
      ) {
        return alertError(cms, 'Die Clusters-Liste enthält doppelte Einträge!')
      } else if (
        formData.metainfo.areas &&
        checkIfDuplicateExists(formData.metainfo.areas)
      ) {
        return alertError(cms, 'Die Bereichs-Liste enthält doppelte Einträge!')
      }

      if (
        formData.contact &&
        formData.contact.jobMailsCc &&
        formData.contact.jobMailsCc.length > 0
      ) {
        let jobMailsCcValid = true
        formData.contact.jobMailsCc.map((aJobMailCc) => {
          if (!isValidEmail(aJobMailCc)) {
            jobMailsCcValid = false
          }
        })
        if (!jobMailsCcValid) {
          return alertError(
            cms,
            'Das Feld "Cc-Adressen für Bewerbungen" enthält eine ungültige Email-Adresse!'
          )
        }
      }

      cms.alerts.info('Änderungen werden gespeichert...')
      return fetch(`/_cms/stations/${formData._id}`, {
        method: 'PUT',
        body: JSON.stringify({
          ...formData,
          slug: newSlug,
        }),
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      })
        .then((response) => {
          if (response.ok) {
            cms.alerts.success('Speichern erfolgreich.')
          } else if (response.status === 401 || response.status === 403) {
            cms.alerts.error(
              'Sie haben nicht die Berechtigung, den Datensatz zu speichern!',
              5000
            )
          } else {
            response.json().then((data) => {
              console.log(
                `Updating station id ${formData._id} failed: ` +
                  JSON.stringify(data)
              )
              const errorMessage = getStationUpdateErrorMessage(data)
              cms.alerts.error(errorMessage, 8000)
            })
          }
          return response.ok
        })
        .then(async (ok) => {
          if (ok === true) {
            if (hasSlugChanged) {
              cms.alerts.info('Weiterleiten zum neuen Pfad...')
              await refreshAndRedirect(newSlug, 2000)
            } else {
              await refreshSourcedContent()
            }
          }
        })
        .catch((e) => {
          cms.alerts.error('Fehler beim Speichern!')
          console.log('Updating station failed: ' + e)
        })
    },
    ...initialDataLoader,
  }

  const [editableData, form] = useForm(StationForm)

  usePlugin(form)
  const stationId =
    editableData && editableData.metainfo && editableData.metainfo.stationId
      ? editableData.metainfo.stationId
      : ''

  return (
    <CMSWrapper>
      <GlobalHelmet
        title={
          editableData.header
            ? `UKE jukebox - ${editableData.header.title}`
            : 'UKE jukebox'
        }
      />
      <main>
        <Navigation stationId={stationId} />
        <Editor form={form}>
          <InlineForm form={form}>
            <StationHeader
              data={editableData}
              entityForm={form}
              locationState={location.state}
              stationId={stationId}
            />
            <StationStats />
            <InlineGroup
              name="content"
              focusRing={{ offset: { x: -32, y: 0 }, borderRadius: 0 }}
            >
              <InlineBlocks
                name="sections"
                blocks={getSectionBlocks(entityContext)}
                itemProps={{ entityContext: entityContext, entityForm: form }}
              />
            </InlineGroup>
            {stationBlogs.length > 0 && (
              <StaticBlogs
                sliderId={station._id}
                blogsData={stationBlogs}
                headline="Blogeinträge zu dieser Station"
              />
            )}
            <StationContactButtons data={editableData} />
            <InlineGroup
              name="contact"
              focusRing={{ offset: { x: -32, y: 0 }, borderRadius: 0 }}
            >
              <InlineBlocks
                name="contactInfos"
                blocks={CONTACT_INFO_BLOCKS}
                itemProps={{ entityContext: entityContext }}
              />
            </InlineGroup>
          </InlineForm>
        </Editor>
        <StaticReferenceModules referencesData={stationReferences} /> <Footer />
      </main>
    </CMSWrapper>
  )
}

const SidebarStatBlock = {
  label: 'Icon',
  key: 'Icon',
  defaultItem: {
    text: 'Titel',
    number: '99',
  },
  fields: [
    {
      name: 'filename',
      label: 'Dateiname',
      component: 'image',
      parse: (media) => MediaSettings.reposPath + media.filename,
      uploadDir: () => '/stations/icons/',
    },
    { name: 'text', label: 'Titel', component: 'text' },
    { name: 'number', label: 'Zahl', component: 'text' },
  ],
}

const ContactInfosBlock = {
  label: 'Info',
  name: 'contactInfo',
  fields: [
    {
      name: 'contactNames',
      label: 'Ansprechpartner',
      component: 'textarea',
    },
  ],
}

const CONTACT_INFO_BLOCKS = {
  ContactInfo: {
    Component: StationContact,
    template: ContactInfosBlock,
  },
}

const SidebarContactInfoBlock = (entityContext) => {
  return {
    label: 'Info',
    key: 'SidebarContactInfo',
    fields: [
      {
        label: 'Bild',
        name: 'image',
        component: 'blocks',
        templates: {
          Image: SidebarImageBlock(entityContext, false),
        },
      },
      {
        label: 'Ansprechpartner',
        name: 'contactNames',
        component: 'textarea',
      },
      {
        label: 'Kontaktwege',
        name: 'contactChannels',
        component: 'blocks',
        templates: {
          Phone: ContactChannelPhoneBlock,
          Mobile: ContactChannelMobileBlock,
          Mail: ContactChannelMailBlock,
        },
      },
    ],
  }
}

function checkIfDuplicateExists(arr) {
  return new Set(arr).size !== arr.length
}

const getStationUpdateErrorMessage = (jsonResponse) => {
  // ggf. detaillierteres Fehlerhandling im Backend implementieren, spezielle Fehlercodes, so dass
  // der Fehler hier nicht geparst werden muss
  const errMsg = jsonResponse.message
  if (
    errMsg.includes('E11000 duplicate key error') &&
    (errMsg.includes('title:') ||
      errMsg.includes('slug:') ||
      errMsg.includes('mediaDir:'))
  ) {
    return 'Speichern nicht möglich: Der Stations-Titel wird bereits verwendet!'
  } else {
    return 'Fehler beim Speichern!'
  }
}

export const query = graphql`
  query StationQuery($id: String) {
    station(_id: { eq: $id }) {
      contact {
        headline
        jobMail
        jobMailsCc
        headlineContactPerson
        contactInfos {
          _template
          contactNames
          image {
            _template
            altText
            description
            filename
            showFrame
          }
          contactChannels {
            _template
            text
          }
        }
      }
      content {
        sections {
          _template
          bgColor
          grids {
            _template
            columns {
              _id
              _template
              altText
              description
              descriptionAlignment
              filename
              images {
                _template
                altText
                description
                filename
                showFrame
              }
              showFrame
              listStyle
              text
              textAlign
              url
              href
              align
              buttonBgColor
              label
            }
            itemAlignment
            marginType
          }
        }
      }
      createdAt
      createdBy
      header {
        handlettering {
          _template
          altText
          description
          filename
          showFrame
        }
        hasVacancies
        image {
          _template
          altText
          description
          filename
          showFrame
        }
        introText
        slogan
        title
      }
      isStationOfWeek
      mediaDir
      metainfo {
        areas
        clusters
        slug
        stationId
        suitableForGeriatricNurses
        tags
      }
      slug
      statistic {
        icons {
          _template
          filename
          number
          text
        }
      }
      updatedAt
      updatedBy
      isDraft
      _id
    }
    allCluster {
      nodes {
        handlettering
        key
        landigpageQuote
        landingpageTitle
        title
        path
        _id
      }
    }
    allBlog(
      filter: { metainfo: { station: { eq: $id } }, isDraft: { eq: false } }
    ) {
      nodes {
        title
        author
        mediaDir
        metainfo {
          author
          station
        }
        slug
        station
        subtitle
        updatedAt
        updatedBy
        createdAt
        header {
          image {
            _template
            altText
            description
            filename
            showFrame
          }
          subtitle
          title
        }
        _id
      }
    }
    allReference(filter: { types: { in: "Station" }, isDraft: { eq: false } }) {
      nodes {
        title
        _id
        types
        content {
          sections {
            _template
            bgColor
            grids {
              _template
              columns {
                _id
                _template
                altText
                description
                descriptionAlignment
                filename
                images {
                  _template
                  altText
                  description
                  filename
                  showFrame
                }
                showFrame
                listStyle
                text
                textAlign
                url
                href
                align
                buttonBgColor
                label
              }
              itemAlignment
              marginType
            }
          }
        }
        updatedBy
        updatedAt
        createdAt
        createdBy
        mediaDir
        slug
        priority
      }
    }
  }
`
